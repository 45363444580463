import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <p style={{marginBottom: `5px`}}>Frontend developer</p>
    <p>React, jQuery, CSS/HTML</p>
  </Layout>
)

export default IndexPage
